import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, BlogHeader, JoinCommunity } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Link } from 'gatsby'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location, pageContext }) => {
    const post = data.ghostPost

    console.log(post)

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout isHome={true} location={location} pageContext={pageContext}>
                <div className="container">
                    <BlogHeader/>
                    <article className="content">
                        <h1 className="content-title">{post.title}</h1>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                          <div className="post-card-avatar">
                              {post.primary_author.profile_image ?
                                  <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                                  <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name}/>
                              }
                          </div>
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', height: '20px'}}>
                              <div style={{fontSize: '14px', marginRight: '5px', height: '25px'}}>Written by</div>
                              <div style={{fontSize: '14px', fontWeight: 'bold', height: '25px'}}>{ post.primary_author.name }</div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                              <div style={{fontSize: '14px', marginRight: '5px'}}>{post.created_at_pretty}</div>
                              <div style={{fontSize: '14px'}}>{ ` · ${post.reading_time} min read` }</div>
                            </div>
                          </div>
                        </div>

                        { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }
                        <section className="post-full-content">
                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
                <JoinCommunity/>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
